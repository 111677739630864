@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --bg-clr: #102432;
  --bg-light-color: #152f40;
  --text-clr: #ffffff;
  --accent-clr: #ffda17;
  /* e88e29 df8118 ffda17 */
  /*========== Colors ==========*/
  --first-color: #ffda17;
  --first-color-light: #152f40;
  --body-color: #102432;
  /* master codigo */
  /* --font-base: "DM Sans", sans-serif; */

  --primary-color: #152f40;
  --secondary-color: #ffda17;
  --black-color: #ffffff;
  --lightGray-color: #e4e4e4;
  --gray-color: #ffffff;
  --brown-color: #46364a;
  --white-color: #152f40;
}

/* reset default styles */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

html,
body {
  background: var(--bg-clr);
  color: var(--text-clr);
  font-family: Poppins;
  background-image: url(./assets/bg-texture.png);
}
/* General styles */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

nav {
  background: rgba($color: #000000, $alpha: 0.1);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.8rem;
  border-radius: 50%;
  display: flex;
  color: #ffda17;
  font-size: 1.4rem;
  // transition: 0.3s;
}

nav a:hover {
  background: rgba($color: #000000, $alpha: 0.2);
}

nav a.active {
  background: #132a3a;
  color: aliceblue;
}
// icons tooltip

.social__content {
  position: relative;
}

.social__tooltip {
  opacity: 0;
  position: absolute;
  padding: 0.5rem 1rem;
  background-color: #ffda17;
  color: #152f40;
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 0.5rem;
  pointer-events: none;

  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #ffda17;
    border-radius: 3px;
  }
}
.social__tooltip,
.social__content {
  transition: 0.3s;
}

.social__tooltip-top {
  top: -2.25rem;
  left: 50%;
  transform: translateX(-50%);
  &::after {
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    bottom: 1px;
  }
}
.social__content:hover .social__tooltip-top {
  opacity: 1;
  top: -3.25rem;
}

.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app__footer-cards {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4em 2rem 2rem;

  .app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fef4f5;

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 500;
    }
    a {
      text-decoration: none;
      font-weight: 500;
    }
    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__footer-cards .app__footer-card:last-child {
  background-color: transparent;
  box-shadow: 0px 0px 20px rgba($color: #ffff, $alpha: 0.2);
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 25px #f2f7fb;
}

.app__footer-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: transparent;
    box-shadow: 1px 5px 1px rgba($color: #fdda26, $alpha: 0.3);
    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: transparent;

      font-family: Poppins;
      color: #fff;
      outline: none;
      font-size: 200;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 1px 5px 1px #25516e;
    }
  }

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    font-weight: bold;

    display: flex;
    flex-direction: row;

    border: none;
    background: linear-gradient(360deg, #fdda26 0%, #ffc72c 35%, #febe10 100%);
    color: #000;
    outline: none;
    margin: 2rem 0 0 0;
    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 25px rgba($color: #febe10, $alpha: 0.2);
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__work-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 2rem 0 1rem;

  .app__work-filter-item {
    padding: 0.5rem 1rem;
    margin: 0.3rem;
    border-radius: 0.5rem;
    background: radial-gradient(
      circle,
      #265370 0%,
      rgba(21, 47, 64, 1) 35%,
      rgba(16, 36, 50, 1) 100%
    );
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-weight: 800;
    transition: all 0.4s ease;

    &:hover {
      background-color: var(--secondary-color);
      box-shadow: 0px 0px 20px rgba($color: #fff, $alpha: 0.1);
    }
    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }
  .item-active {
    background: linear-gradient(
      360deg,
      rgba(253, 218, 38, 1) 0%,
      #ffc72c 35%,
      #febe10 100%
    );
    color: #000;
  }
}

.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__work-item {
    width: 270px;
    flex-direction: column;

    margin: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: transparent;
    box-shadow: 0px 0px 20px rgba($color: #ffff, $alpha: 0.3);
    color: #fff;
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      box-shadow: 0 0 25px rgba($color: #25516e, $alpha: 1);
    }
    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }
    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}

.app__work-img {
  width: 100%;
  height: 230px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }
  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: rgb(255, 238, 238), $alpha: 0.5);
  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba($color: #152f40, $alpha: 0.5);
    color: rgb(198, 35, 35);
    margin: 1rem;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    svg {
      height: 50%;
      width: 50%;
      color: #ffda17;
    }
  }
}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;
  h4 {
    margin-top: 1.5em;
    line-height: 1.5;
  }
  .app__work-tag {
    position: absolute;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #102330;
    top: -25px;
  }
}
